export const THEME = {
  dark: {
    primaryText: 'text-gray-100',
    secondaryText: 'text-gray-400',
    primaryBg: 'bg-gray-800',
    secondaryBg: 'bg-gray-700',
    greenBg: 'bg-green-900',
    indigoTextAccent: 'text-indigo-400',
    blueTextAccent: 'text-blue-200',
    greenTextAccent: 'text-green-200',
    logo: 'light',
    activeNav: 'active-nav-dark',
    shadow: '',
  },
  light: {
    primaryText: 'text-gray-800',
    secondaryText: 'text-gray-600',
    primaryBg: 'bg-white',
    secondaryBg: 'bg-gray-200',
    greenBg: 'bg-green-100',
    indigoTextAccent: 'text-indigo-600',
    blueTextAccent: 'text-blue-700',
    greenTextAccent: 'text-green-700',
    activeNav: 'active-nav-light',
    shadow: 'shadow-custom',
  },
};
